$(function() {
  $('#hamburger').on('show.bs.collapse', function () {
    $('body').addClass('is-open-navigation');
  })
  $('#hamburger').on('hide.bs.collapse', function () {
    $('body').removeClass('is-open-navigation');
  })

  //blank
  $('.entry-content').find('a[href^=http], a[href^=https]').attr('target','_blank');

  //follow
  $('.js-followsidebar').followsidebar();
});
