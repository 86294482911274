/*
 *  Project:
 *  Description:
 *  Author:
 *  License:
 *  Howto:

    $('.js-followsidebar').followsidebar();
*/

;(function(jQuery) {

  var pluginName = 'followsidebar';
  $[pluginName] = function(element, options) {

    var defaults = {
        pluginName: pluginName,
        lastY: 0,
        offsetTop: 0,//sidebarの始まる位置のY座標
        fixW: 0,//private 固定幅の時のsidebarの幅
        sidebarH: 0, //初回表示時のsidebarの高さ
        fixedY: 0, //切り替え位置: fixed
        bottomY: 0, //切り替え位置: bottom
        debug: ''
    }
    var plugin = this;
    plugin.settings = {};

    plugin.init = function() {
      plugin.settings = $.extend({}, defaults, options);
      plugin.initListner();
    },

    //----------------------------------------------------------------------

    plugin.initListner = function(e) {

      if($(element).height() == $(element).parent().parent().height()) {
          return false;
      }

      plugin.settings.lastY = 0;
      plugin.settings.offsetTop = $(element).parent().offset().top;
      plugin.settings.sidebarH = $(element).height();

      $(window).on('scroll', _.throttle(plugin.updateScroll, 30));
      $(window).on('resize', _.throttle(plugin.resize, 100));

      plugin.resize();
    },

    plugin.resize = function(e) {
      //リサイズ時に確認しておきたい長さ
      plugin.settings.sidebarH = $(element).height();
      plugin.settings.offsetTop = $(element).parent().offset().top;
      plugin.settings.fixW = $(element).parent().width();
      if($(element).hasClass('is-bottom') || $(element).hasClass('is-fixed')) {
        // $(element).css('width', plugin.settings.fixW);
      }

      //親カラムを探す
      var $parentColumn = $(element).parent().parent();

      if($(window).height() < plugin.settings.sidebarH) {
        // console.log('//sidebarがウィンドウより長い場合');
        plugin.settings.bottomY = $parentColumn.height() + plugin.settings.offsetTop - $(window).height();
      } else {
        // console.log('//sidebarがウィンドウより短い場合');
        plugin.settings.bottomY = $parentColumn.height() + plugin.settings.offsetTop - plugin.settings.sidebarH;
      }
      plugin.settings.fixedY = Math.max(plugin.settings.offsetTop + plugin.settings.sidebarH - $(window).height(), plugin.settings.offsetTop);
    },

    plugin.updateScroll = function(e) {
      plugin.resize();

      //scroll処理
      var y = $(window).scrollTop();
      if (y < plugin.settings.lastY) {
        plugin.scrollUp(y);
      } else if (y > plugin.settings.lastY) {
        plugin.scrollDown(y);
      }
      plugin.settings.lastY = $(window).scrollTop();
    },

    plugin.scrollUp = function(y) {
      // console.log('scrollUp, ', y);
      if (y > plugin.settings.bottomY) {
        plugin.changeMode('bottom');
      } else if (y > plugin.settings.fixedY) {
        plugin.changeMode('fixed');
      } else {
        plugin.changeMode('static');
      }
    },

    plugin.scrollDown = function(y) {
      // console.log('scrollDown, ', y);
      if (y > plugin.settings.bottomY) {
        plugin.changeMode('bottom');
      } else if (y > plugin.settings.fixedY) {
        plugin.changeMode('fixed');
      } else {
        plugin.changeMode('static');
      }
    },

    plugin.changeMode = function(mode) {
      var $parentColumn = $(element).parent().parent();

      switch (mode) {
        case 'fixed':
          if(!$(element).hasClass('is-fixed')) {
            $(element).removeClass('is-bottom');
            $(element).addClass('is-fixed');
            $(element).attr('style', '');
            var css = {
              // 'width': plugin.settings.fixW,
              'bottom': $(window).height() - plugin.settings.sidebarH
            };
            if($(window).height() < plugin.settings.sidebarH) {
              //sidebarがウィンドウより長い場合
              css['bottom'] = 0;
            }
            $(element).css(css);
          }
          break;
        case 'bottom':
          if(!$(element).hasClass('is-bottom')) {
            $(element).removeClass('is-fixed');
            $(element).addClass('is-bottom');

            var minH = Math.max($(window).height(), plugin.settings.sidebarH);

            var top = 0;
            if($(window).height() < plugin.settings.sidebarH) {
              // console.log('//sidebarがウィンドウより長い場合');
              top = $parentColumn.height() - $(window).height();
            } else {
              // console.log('//sidebarがウィンドウより短い場合');
              top = $parentColumn.height() - plugin.settings.sidebarH;
            }
            var css = {
              'top': Math.max(top, 0),
              'bottom': 'auto',
              // 'width': plugin.settings.fixW
            };
            if($(window).height() < plugin.settings.sidebarH) {
              css['top'] = $parentColumn.height() - plugin.settings.sidebarH;
            }
            $(element).css(css);
          }
          break;
        case 'static':
          if($(element).hasClass('is-fixed')) {
            $(element).removeClass('is-fixed');
          }
          if($(element).hasClass('is-bottom')) {
            $(element).removeClass('is-bottom');
          }
          $(element).attr('style', '');
          break;
      }
    },

    plugin.log = function() {
      if(plugin.settings.debug == '') return;
      if(typeof console == "undefined") return;
      $(plugin.settings.debug).prepend(console, jQuery.makeArray(arguments));
    },

    //----------------------------------------------------------------------

    plugin.init();
  }

  $.fn[pluginName] = function(options) {if(!options) options = {};options.items = [];return this.each(function(i) {options.id = i;options.items.push($(this));if (undefined == $(this).data(pluginName)) {var plugin = new $[pluginName](this, options);$(this).data(pluginName, plugin);}});}

})(jQuery);
